<template>
  <span>
    <v-btn small class="rounded-lg" text @click="dialog = !dialog" color="primary">Add Card</v-btn>
    <!-- <v-card width="200" @click="dialog = !dialog" :ripple="false" height="200" class="button-30 rounded-xl">
      <v-card-text style="height: 100%">
        <v-layout column fill-height align-center>
          <v-flex shrink>
            <v-img height="100" contain src="@/assets/bill.png"></v-img>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex class="text-h6 font-weight-bold">
            Bill Payment
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card> -->

    <v-dialog v-model="dialog" width="400" persistent>
      <!-- <template v-slot:activator="{ on, attrs }">
        
      </template> -->

      <v-card class="rounded-xl" elevation="15">
        <v-card-title class="text-h5">
          Add Card
          <v-spacer></v-spacer>
          <v-btn text icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>



        <v-card-text class="pb-0 mt-8">
          <v-card outlined>
            <v-card-text>
              <StripeElements :stripe-key="stripeKey" :instance-options="instanceOptions"
                :elements-options="elementsOptions" #default="{ elements }" ref="elms">
                <StripeElement type="card" :elements="elements" :options="cardOptions" ref="card" />
              </StripeElements>
            </v-card-text>

          </v-card>
        </v-card-text>
        <v-card-text class="pt-0">
          <v-card-text pa-0 ma-0>
            <v-img src="@/assets/stripe.png" />
          </v-card-text>
        </v-card-text>



        <!-- <div class="payment-simple">

          <button type="button">Pay</button>
        </div> -->

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-btn class="rounded-lg" large block :loading="loading" @click="submit" color="primary">
                Save
              </v-btn>
            </v-col>

          </v-row>
        </v-card-text>


        <!-- <v-card-actions class="mb-5">
          <v-btn class="rounded-lg" block :disabled="phoneNumberCheck" :loading="loading" @click="submit" color="primary">
            Submit
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </span>

</template>

<script>
import homeService from "@/modules/Home/service.js";
// import EventBus from '@/event-bus.js';
import stripeService from '@/modules/Wallet/service.js'
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'
// const { validationMixin } = require("vuelidate");
// const { required, minLength, requiredIf } = require("vuelidate/lib/validators");

export default {
  name: 'add-funds',
  props: ['contact'],
  data() {
    return {
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
      },
      cardOptions: {
        // reactive
        // remember about Vue 2 reactivity limitations when dealing with options
        // value: {
        //   postalCode: ''
        // }
        // https://stripe.com/docs/stripe.js#element-options
      },
      stripeKey: process.env.VUE_APP_STRIPE_PK,
      carrierLoading: true,
      phoneNumber: null,
      confirmPhoneNumber: null,
      simInformation: null,
      selectedCarrier: null,
      amount: null,
      fee: 0,
      dialog: false,
      loading: false,
      description: null,
      Carriers: [],
      limit: 1,
      orderBy: ['id', 'true'],
    }
  },
  components: {
    StripeElements,
    StripeElement,
  },
  computed: {
    total() {
      return parseFloat(this.amount) + parseFloat(this.fee)
    },
    simCardErrors() {
      const errors = [];
      if (!this.$v.simcard.$dirty) return errors;
      if (!this.$v.simcard.required) errors.push("Sim card number is required");
      if (!this.$v.simcard.minLength)
        errors.push("Number must be atleast 14 digits");
      return errors;
    },
    pinNumberErrors() {
      const errors = [];
      if (!this.$v.pinNumber.$dirty) return errors;
      if (!this.$v.pinNumber.required) errors.push("Pin number is required");
      if (!this.$v.pinNumber.minLength)
        errors.push("Pin Number must be atleast 6 digits");
      return errors;
    },
    selectPlanErrors() {
      const errors = [];
      if (!this.$v.selectedPlan.$dirty) return errors;
      if (!this.$v.selectedPlan.required)
        errors.push("Select plan is required");
      return errors;
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.empty()
      }
    }
  },
  // mixins: [validationMixin],
  // validations() {
  //   return {
  //     phoneNumber: {
  //       required,
  //       minLength: minLength(10),
  //     },
  //     amount: {
  //       required,
  //     },
  //     fee: {
  //       required,
  //       minLength: minLength(6),
  //     },
  //   };
  // },
  methods: {
    searchPhoneNumber() {
      console.log('searchPhoneNumber', this.phoneNumber)
      if (this.phoneNumber) {
        return homeService.BillPayment.getAll(
          {
            phoneNumber: this.phoneNumber,
            limit: this.limit,
            orderBy: this.orderBy,
          })
          .then((response) => {
            console.log("BillPayment response", response);
            if (response.data.length) {
              this.amount = response.data[0].amount
              this.fee = response.data[0].fee
            }

          })
          .catch((error) => {
            console.log("error", error);
          });
      }

    },
    empty() {
      this.phoneNumber = null
      this.confirmPhoneNumber = null
      this.amount = 0
      this.fee = 0
    },
    submit() {
      this.loading = true
      const groupComponent = this.$refs.elms
      const cardComponent = this.$refs.card
      // Get stripe element
      const cardElement = cardComponent.stripeElement

      // Access instance methods, e.g. createToken()
      groupComponent.instance.createToken(cardElement).then(result => {
        // Handle result.error or result.token
        console.log('result', result)
        if (result && result.error) {
          this.$toast.open({
            message: result.error.message,
            dismissible: true,
            type: 'error',
            position: 'top-right',
          });
          this.loading = false
        } else {
          return stripeService.addCard({ ContactId: this.contact.id, token: result })
            .then(result => {

              this.$emit('submit')
              this.loading = false
              this.dialog = false
            }).catch(error => {
              this.loading = false
              this.$swal(
                "Add Card",
                error.data.message,
                "error"
              );
              // this.$toast.open({
              //   message: error.data.message,
              //   dismissible: true,
              //   type: 'error',
              //   position: 'top-right',
              // });
              console.log('error adding card', error)
            })
        }



      })

    },
  }
}
</script>

<style scoped>
input {
  /* border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  background-color: #eee; */
  font-size: 72px;
  text-align: center;
  width: 220px;
  color: gray;
}

.no-outline:focus {
  outline: none;
  font-size: 72px;
}

/* CSS */
</style>