import cellsimPortalService from '@/api/cellSim.js';

export default {
  /**
   * Get list of products
   * @param {*} params
   * @param {*} params.limit
   * @param {*} params.offset
   * @param {*} params.search
   * @param {*} params.local Add LocalData to products
   */
   addCard(data) {
    return cellsimPortalService.Api().post('stripe/addNewCard', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  getCustomerCard(id) {
    return cellsimPortalService.Api().get(`stripe/getCustomerCard/${id}`)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  addFunds(data) {
    return cellsimPortalService.Api().post(`stripe/addFunds`, data )
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  deleteCard(data) {
    return cellsimPortalService.Api().post(`stripe/deleteCard`, data )
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  getCustomer(id) {
    return cellsimPortalService.Api().get(`stripe/getCustomerInfo/${id}`)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  

}
